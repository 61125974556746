import { db } from './firebase.js';
import { getDoc, setDoc, updateDoc, increment, arrayRemove, collection, doc } from 'firebase/firestore';

/* Firebase Firestore functions */

const initializeUserData = async (userId, email) => {
  try {
    const userRef = doc(db, 'users', userId);
    await setDoc(userRef, {
      email: email,
      storiesGenerated: 0,
      isAdmin: false,
      hasPaid: false,
      subscriptionStatus: 'free',
      subscriptionEndDate: null,
      createdAt: new Date().toISOString(),
      lastLogin: new Date().toISOString(),
      points: 0,
      childProfiles: []
    }, { merge: true }); // This will create the document if it doesn't exist, or update it if it does

    console.log(`User data initialized for userId: ${userId}`);
  } catch (error) {
    console.error(`Error initializing user data for userId: ${userId}`, error);
    throw error;
  }
};


const incrementStoriesGenerated = async (userId) => {
  try {
    console.log(`Incrementing stories for user: ${userId}`);
    const usersCollection = collection(db, 'users');
    const userRef = doc(usersCollection, userId);
    await updateDoc(userRef, { storiesGenerated: increment(1) });
  } catch (error) {
    console.error(`Error incrementing stories for userId: ${userId}`, error);
    throw error;
  }
};

const updateChildProfilePoints = async (userId, childProfileId, pointsToAdd) => {
  try {
    console.log(`Updating points for child profile: ${childProfileId} of user: ${userId}`);
    const usersCollection = collection(db, 'users');
    const userRef = doc(usersCollection, userId);
    const userDoc = await getDoc(userRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      const updatedChildProfiles = userData.childProfiles.map(profile => {
        if (profile.id === childProfileId) {
          return {
            ...profile,
            points: (profile.points || 0) + pointsToAdd
          };
        }
        return profile;
      });

      await updateDoc(userRef, { childProfiles: updatedChildProfiles });
      console.log(`Successfully updated points for child profile ${childProfileId} of user ${userId}`);
    } else {
      throw new Error('User document not found');
    }
  } catch (error) {
    console.error(`Error updating points for child profile ${childProfileId} of user ${userId}:`, error);
    throw error;
  }
};

const getUserData = async (userId) => {
  try {
    const userRef = doc(db, 'users', userId);
    const userSnap = await getDoc(userRef);

    if (userSnap.exists()) {
      const userData = userSnap.data();
      console.log('Retrieved user data:', userData);
      console.log('Subscription status:', userData.subscriptionStatus);
      console.log('Cancel at period end:', userData.cancelAtPeriodEnd);
      return {
        ...userData,
        childProfiles: userData.childProfiles || []
      };
    } else {
      console.log('No user document found for userId:', userId);
      const newUserData = {
        email: '',
        storiesGenerated: 0,
        isAdmin: false,
        hasPaid: false,
        subscriptionStatus: 'free',
        subscriptionEndDate: null,
        createdAt: new Date().toISOString(),
        lastLogin: new Date().toISOString(),
        points: 0,
        childProfiles: []
      };
      await setDoc(userRef, newUserData);
      console.log('Created new user document for userId:', userId);
      return newUserData;
    }
  } catch (error) {
    console.error('Error in getUserData:', error);
    throw error;
  }
};

const setAdminStatus = async (userId, isAdmin) => {
  const userRef = doc(db, 'users', userId);
  await updateDoc(userRef, { isAdmin });
};

const updateSubscriptionStatus = async (userId, status, endDate, subscriptionId, cancelAtPeriodEnd = false) => {
  console.log(`Updating subscription for user ${userId}. Status: ${status}, EndDate: ${endDate}, SubscriptionId: ${subscriptionId}, CancelAtPeriodEnd: ${cancelAtPeriodEnd}`);
  const userRef = doc(db, 'users', userId);
  const updateData = {
    subscriptionStatus: status,
    subscriptionEndDate: endDate ? new Date(endDate).toISOString() : null,
    hasPaid: status === 'active',
    stripeSubscriptionId: subscriptionId,
    cancelAtPeriodEnd: cancelAtPeriodEnd
  };

  try {
    await updateDoc(userRef, updateData);
    console.log(`Subscription updated for user ${userId}:`, updateData);
  } catch (error) {
    console.error(`Error updating subscription status for user ${userId}:`, error);
    throw error;
  }
};

const resetStoriesGenerated = async (userId) => {
  const userRef = doc(db, 'users', userId);
  await updateDoc(userRef, { storiesGenerated: 0 });
};

const checkSubscriptionStatus = async (userId) => {
  const userData = await getUserData(userId);
  if (!userData) return false;

  if (userData.subscriptionStatus === 'active') {
    if (userData.subscriptionEndDate) {
      const endDate = new Date(userData.subscriptionEndDate);
      if (endDate > new Date()) {
        return true;
      } else {
        // Subscription has expired
        await updateSubscriptionStatus(userId, 'expired');
        return false;
      }
    }
    return true;
  }
  return false;
};

const canGenerateStory = async (userId) => {
  const userData = await getUserData(userId);
  if (!userData) return false;

  if (await checkSubscriptionStatus(userId)) return true;
  return userData.storiesGenerated < 3;
};

const cancelSubscription = async (userId, cancellationReason) => {
  const userRef = doc(db, 'users', userId);
  await updateDoc(userRef, {
    subscriptionStatus: 'cancelled',
    subscriptionEndDate: null,
    hasPaid: false,
    stripeSubscriptionId: null,
    cancellationReason: cancellationReason,
    cancellationDate: new Date().toISOString()
  });
};

const storeSubscriptionId = async (userId, subscriptionId) => {
  const userRef = doc(db, 'users', userId);
  await updateDoc(userRef, {
    stripeSubscriptionId: subscriptionId
  });
};

const updateUserPoints = async (userId, pointsToAdd) => {
  try {
    console.log(`Updating points for user: ${userId}`);
    const usersCollection = collection(db, 'users');
    const userRef = doc(usersCollection, userId);
    await updateDoc(userRef, { points: increment(pointsToAdd) });
    console.log(`Successfully updated points for user ${userId}`);
  } catch (error) {
    console.error(`Error updating points for userId: ${userId}`, error);
    throw error;
  }
};

const addChildProfile = async (userId, childProfile) => {
  try {
    const userRef = doc(db, 'users', userId);
    const userSnap = await getDoc(userRef);

    if (userSnap.exists()) {
      const userData = userSnap.data();
      const updatedProfiles = [...(userData.childProfiles || []), childProfile];

      await updateDoc(userRef, {
        childProfiles: updatedProfiles
      });

      console.log(`Child profile added for user ${userId}`);
      return updatedProfiles;
    } else {
      throw new Error('User document not found');
    }
  } catch (error) {
    console.error(`Error adding child profile for user ${userId}:`, error);
    throw error;
  }
};

const updateChildProfile = async (userId, childProfileId, updatedData) => {
  try {
    const userRef = doc(db, 'users', userId);
    const userData = await getUserData(userId);
    const updatedProfiles = userData.childProfiles.map(profile =>
      profile.id === childProfileId ? { ...profile, ...updatedData } : profile
    );
    await updateDoc(userRef, { childProfiles: updatedProfiles });
    console.log(`Child profile updated for user ${userId}`);
  } catch (error) {
    console.error(`Error updating child profile for user ${userId}:`, error);
    throw error;
  }
};

const removeChildProfile = async (userId, childProfileId) => {
  try {
    const userRef = doc(db, 'users', userId);
    const userData = await getUserData(userId);
    const profileToRemove = userData.childProfiles.find(profile => profile.id === childProfileId);
    if (profileToRemove) {
      await updateDoc(userRef, {
        childProfiles: arrayRemove(profileToRemove)
      });
      console.log(`Child profile removed for user ${userId}`);
    }
  } catch (error) {
    console.error(`Error removing child profile for user ${userId}:`, error);
    throw error;
  }
};

export {
  initializeUserData,
  incrementStoriesGenerated,
  getUserData,
  setAdminStatus,
  updateSubscriptionStatus,
  resetStoriesGenerated,
  checkSubscriptionStatus,
  canGenerateStory,
  cancelSubscription,
  storeSubscriptionId,
  updateUserPoints,
  addChildProfile,
  updateChildProfile,
  removeChildProfile,
  updateChildProfilePoints
};