import React from 'react';
import './WarriorLoadingAnimation.css';

const WarriorLoadingAnimation = () => {
  return (
    <div className="warrior-loading-container">
      <div className="warrior-book">
        <div className="book-page"></div>
        <div className="book-page"></div>
        <div className="book-page"></div>
      </div>
      <p className="loading-text">Crafting your adventure...</p>
    </div>
  );
};

export default WarriorLoadingAnimation;