import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from './AuthContext.js';
import { doc, updateDoc, arrayUnion, collection } from 'firebase/firestore';
import { db } from './firebase.js';
import CancellationModal from './CancellationModal.js';

const readingLevels = [
  { id: "early-reader", name: "Early Reader (Ages 3-5)", description: "Simple words, lots of pictures" },
  { id: "beginning-reader", name: "Beginning Reader (Ages 5-7)", description: "Short sentences, some new words" },
  { id: "confident-reader", name: "Confident Reader (Ages 7-9)", description: "Longer stories, more complex words" },
  { id: "advanced-reader", name: "Advanced Reader (Ages 9-12)", description: "Chapter books, rich vocabulary" }
];

const UserProfile = () => {
  const { user, userData, refreshUserData } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showAddProfile, setShowAddProfile] = useState(false);
  const [childName, setChildName] = useState('');
  const [childAge, setChildAge] = useState('');
  const [childReadingLevel, setChildReadingLevel] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);
  const [showCancellationModal, setShowCancellationModal] = useState(false);

  useEffect(() => {
    console.log('UserProfile userData:', userData);
    console.log('Subscription status:', userData?.subscriptionStatus);
    console.log('Cancel at period end:', userData?.cancelAtPeriodEnd);
  }, [userData]);

  const getSubscriptionStatus = useCallback(() => {
    if (userData?.subscriptionStatus === 'active' && userData?.cancelAtPeriodEnd) {
      return 'Cancellation Pending';
    }
    return userData?.subscriptionStatus || 'Unknown';
  }, [userData]);

  useEffect(() => {
    if (!userData) {
      refreshUserData();
    }
  }, [userData, refreshUserData]);

  const handleCancellationStart = () => {
    setShowCancellationModal(true);
  };

  const handleCancellationSubmit = async (reason) => {
    setIsLoading(true);
    setError(null);

    try {
      console.log('Submitting cancellation for user:', user.uid);
      const response = await fetch('/api/cancel-subscription', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: user.uid,
          cancellationReason: reason
        }),
      });

      const data = await response.json();
      console.log('Cancellation response:', data);

      if (!response.ok) {
        throw new Error(data.error || 'Failed to cancel subscription');
      }

      await refreshUserData(true);
      console.log('User data after cancellation:', userData);
      alert('Your subscription has been cancelled successfully.');
      setShowCancellationModal(false);
    } catch (err) {
      console.error('Error cancelling subscription:', err);
      setError(err.message || 'An error occurred while cancelling the subscription');
    } finally {
      setIsLoading(false);
    }
  };
  const handleAddChildProfile = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      const usersCollection = collection(db, 'users');
      const userRef = doc(usersCollection, user.uid);

      await updateDoc(userRef, {
        childProfiles: arrayUnion({
          id: Date.now().toString(),
          name: childName,
          age: Number(childAge),
          readingLevel: childReadingLevel,
        })
      });

      await refreshUserData();
      setShowAddProfile(false);
      setChildName('');
      setChildAge('');
      setChildReadingLevel('');
      alert('Child profile added successfully!');
    } catch (err) {
      console.error('Error in handleAddChildProfile:', err);
      setError(`Error adding child profile: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  if (!userData) return null;

  return (
    <div className="user-profile bg-white shadow rounded-lg overflow-hidden">
      <div
        className="flex justify-between items-center p-3 cursor-pointer"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div>
          <p className="font-semibold truncate">{userData?.email}</p>
          <p className="text-xs sm:text-sm">
            <span className="hidden sm:inline">Stories: {userData?.storiesGenerated} | </span>
            <span className="hidden sm:inline">Status: {getSubscriptionStatus()} </span>
          </p>
        </div>
        {isExpanded ? (
          <span className="h-5 w-5 text-gray-500">▲</span>
        ) : (
          <span className="h-5 w-5 text-gray-500">▼</span>
        )}
      </div>

      {isExpanded && (
        <div className="p-3 border-t">
          {userData.subscriptionStatus === 'active' && !userData.cancelAtPeriodEnd && (
            <button
              onClick={handleCancellationStart}
              disabled={isLoading}
              className="mt-1 text-gray-500 hover:text-gray-700 text-xs underline"
            >
              {isLoading ? 'Processing...' : 'Cancel Subscription'}
            </button>
          )}
          {userData.cancelAtPeriodEnd && (
            <p className="mt-1 text-xs text-orange-500">
              Your subscription will be cancelled on {new Date(userData.subscriptionEndDate).toLocaleDateString()}
            </p>
          )}
          {error && <p className="text-red-500 mt-1 text-xs">{error}</p>}

          {/* Child Profiles Section */}
          <div className="mt-4">
            <h3 className="text-lg font-semibold">Child Profiles</h3>
            {userData.childProfiles && userData.childProfiles.map(profile => (
              <div key={profile.id} className="mt-2 p-2 bg-gray-100 rounded">
                <p>{profile.name} - Age: {profile.age}, Reading Level: {
                  readingLevels.find(level => level.id === profile.readingLevel)?.name || profile.readingLevel
                }</p>
              </div>
            ))}
            <button
              onClick={() => setShowAddProfile(!showAddProfile)}
              className="mt-2 text-blue-500 hover:text-blue-700 text-xs underline"
            >
              {showAddProfile ? 'Cancel' : 'Add Child Profile'}
            </button>
            {showAddProfile && (
              <form onSubmit={handleAddChildProfile} className="mt-2">
                <input
                  type="text"
                  value={childName}
                  onChange={(e) => setChildName(e.target.value)}
                  placeholder="Child's Name"
                  className="w-full p-1 mb-1 border rounded"
                  required
                />
                <input
                  type="number"
                  value={childAge}
                  onChange={(e) => setChildAge(e.target.value)}
                  placeholder="Age"
                  className="w-full p-1 mb-1 border rounded"
                  required
                  min="3"
                  max="12"
                />
                <select
                  value={childReadingLevel}
                  onChange={(e) => setChildReadingLevel(e.target.value)}
                  className="w-full p-1 mb-1 border rounded"
                  required
                >
                  <option value="">Select Reading Level</option>
                  {readingLevels.map((level) => (
                    <option key={level.id} value={level.id}>
                      {level.name} - {level.description}
                    </option>
                  ))}
                </select>
                <button
                  type="submit"
                  disabled={isLoading}
                  className="w-full bg-blue-500 text-white p-1 rounded hover:bg-blue-600"
                >
                  {isLoading ? 'Adding...' : 'Add Profile'}
                </button>
              </form>
            )}
          </div>
        </div>
      )}

      <CancellationModal
        isOpen={showCancellationModal}
        onClose={() => setShowCancellationModal(false)}
        onSubmit={handleCancellationSubmit}
      />
    </div>
  );
};

export default UserProfile;