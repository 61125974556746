import React from 'react';

const LandingPage = ({ onStartAdventure }) => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 via-green-100 to-yellow-100">
      <main className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h1 className="text-4xl font-extrabold text-blue-900 sm:text-5xl md:text-6xl">
            Embark on Epic <span className="text-green-600">Kids Stories</span>
          </h1>
          <p className="mt-3 max-w-md mx-auto text-base text-gray-700 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            Join Reading Warriors and discover a world of short, engaging children's stories tailored just for you!
          </p>
          <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
            <div className="rounded-md shadow">
              <button
                onClick={onStartAdventure}
                className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 md:py-4 md:text-lg md:px-10"
              >
                Start Your Adventure
              </button>
            </div>
          </div>
        </div>

        <div className="mt-16">
          <h2 className="text-3xl font-extrabold text-blue-900">Why Choose Reading Warriors?</h2>
          <div className="mt-8 grid gap-8 md:grid-cols-2 lg:grid-cols-3">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-bold text-blue-600 mb-2">Short, Engaging Stories</h3>
              <p className="text-gray-700">Perfect for young readers with busy imaginations.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-bold text-blue-600 mb-2">Personalized Adventures</h3>
              <p className="text-gray-700">Unique stories every time, tailored to your child's interests.</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-bold text-blue-600 mb-2">Educational Fun</h3>
              <p className="text-gray-700">Learn while enjoying exciting quests and magical journeys.</p>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default LandingPage;