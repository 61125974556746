import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthContext.js';
import { addChildProfile } from './userFunctions.js';

const readingLevels = [
  { id: 'early-reader', name: 'Early Reader (Ages 3-5)', description: 'Simple words, lots of pictures' },
  { id: 'beginning-reader', name: 'Beginning Reader (Ages 5-7)', description: 'Short sentences, some new words' },
  { id: 'confident-reader', name: 'Confident Reader (Ages 7-9)', description: 'Longer stories, more complex words' },
  { id: 'advanced-reader', name: 'Advanced Reader (Ages 9-12)', description: 'Chapter books, rich vocabulary' },
];

const ProfileSelector = ({ onProfileSelect }) => {
  const { user, userData, refreshUserData } = useAuth();
  const [showAddProfile, setShowAddProfile] = useState(false);
  const [childName, setChildName] = useState('');
  const [childAge, setChildAge] = useState('');
  const [childReadingLevel, setChildReadingLevel] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        try {
          await refreshUserData(true);
        } catch (error) {
          console.error('Error fetching user data:', error);
          setError('Failed to load user data. Please try again.');
        }
      }
    };

    fetchUserData();
  }, [user, refreshUserData]);

  const handleAddChildProfile = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      const newProfile = {
        id: Date.now().toString(),
        name: childName,
        age: Number(childAge),
        readingLevel: childReadingLevel,
      };

      await addChildProfile(user.uid, newProfile);
      await refreshUserData(true);

      setShowAddProfile(false);
      setChildName('');
      setChildAge('');
      setChildReadingLevel('');
      // Do not automatically select the new profile
      // onProfileSelect(newProfile.id);
    } catch (err) {
      console.error('Error in handleAddChildProfile:', err);
      setError(`Error adding child profile: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  if (!userData || !userData.childProfiles) {
    return <div>Loading profiles...</div>;
  }

  return (
    <div className="max-w-4xl mx-auto mt-10 p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-3xl font-bold mb-6 text-center text-blue-600">Choose a Reading Warrior</h2>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {userData.childProfiles.map((profile) => (
          <button
            key={profile.id}
            onClick={() => onProfileSelect(profile.id)}
            className="p-4 bg-blue-100 rounded-lg shadow hover:shadow-md transition-shadow"
          >
            <h3 className="text-xl font-semibold mb-2">{profile.name}</h3>
            <p>Age: {profile.age}</p>
            <p>Reading Level: {profile.readingLevel}</p>
          </button>
        ))}

        <button
          onClick={() => setShowAddProfile(true)}
          className="p-4 bg-green-100 rounded-lg shadow hover:shadow-md transition-shadow flex items-center justify-center"
        >
          <span className="text-2xl mr-2">+</span> Add New Warrior
        </button>
      </div>

      {showAddProfile && (
        <div className="mt-8 p-4 bg-gray-100 rounded-lg">
          <h3 className="text-2xl font-bold mb-4">Add New Reading Warrior</h3>
          <form onSubmit={handleAddChildProfile}>
            <div className="mb-4">
              <label htmlFor="childName" className="block text-sm font-medium text-gray-700">Warrior Name</label>
              <input
                type="text"
                id="childName"
                value={childName}
                onChange={(e) => setChildName(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="childAge" className="block text-sm font-medium text-gray-700">Age</label>
              <input
                type="number"
                id="childAge"
                value={childAge}
                onChange={(e) => setChildAge(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                required
                min="3"
                max="12"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="childReadingLevel" className="block text-sm font-medium text-gray-700">Reading Level</label>
              <select
                id="childReadingLevel"
                value={childReadingLevel}
                onChange={(e) => setChildReadingLevel(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                required
              >
                <option value="">Select Reading Level</option>
                {readingLevels.map((level) => (
                  <option key={level.id} value={level.id}>
                    {level.name} - {level.description}
                  </option>
                ))}
              </select>
            </div>
            {error && <p className="text-red-500 mb-4">{error}</p>}
            <div className="flex justify-end">
              <button
                type="button"
                onClick={() => setShowAddProfile(false)}
                className="mr-4 px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                Cancel
              </button>
              <button
                type="submit"
                disabled={isLoading}
                className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                {isLoading ? 'Adding...' : 'Add Warrior'}
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default ProfileSelector;