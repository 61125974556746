import React, { useState } from 'react';

const cancellationReasons = [
  "Too expensive",
  "Not using the service enough",
  "Found a better alternative",
  "Technical issues",
  "Other"
];

const CancellationModal = ({ isOpen, onClose, onSubmit }) => {
  const [selectedReason, setSelectedReason] = useState('');
  const [otherReason, setOtherReason] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(selectedReason === 'Other' ? otherReason : selectedReason);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <h3 className="text-lg font-bold mb-4 text-gray-800">We're sorry to see you go</h3>
        <p className="mb-4 text-gray-700">Please tell us why you're cancelling:</p>
        <form onSubmit={handleSubmit}>
          {cancellationReasons.map((reason) => (
            <div key={reason} className="mb-2 text-gray-700">
              <input
                type="radio"
                id={reason}
                name="cancellationReason"
                value={reason}
                checked={selectedReason === reason}
                onChange={(e) => setSelectedReason(e.target.value)}
                className="mr-2"
              />
              <label htmlFor={reason} className="select-none">{reason}</label>
            </div>
          ))}
          {selectedReason === 'Other' && (
            <textarea
              value={otherReason}
              onChange={(e) => setOtherReason(e.target.value)}
              className="w-full p-2 border rounded text-gray-700"
              placeholder="Please specify..."
            />
          )}
          <div className="mt-4">
            <button
              type="submit"
              className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 mr-2"
            >
              Confirm Cancellation
            </button>
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-300 text-black rounded hover:bg-gray-400"
            >
              Go Back
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CancellationModal;