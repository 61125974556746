import React, { useState, useEffect } from 'react';
import { AuthProvider, useAuth } from './AuthContext.js';
import { signOut } from 'firebase/auth';
import { auth } from './firebase.js';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Login from './Login.js';
import LandingPage from './LandingPage.js';
import StoryGenerator from './StoryGenerator.js';
import UserProfile from './UserProfile.js';
import BlogPage from './BlogPage.js';
import StoryPage from './StoryPage.js';
import InstallPWA from './installPWA.js';
import ProfileSelector from './ProfileSelector.js';

function Header({ user, onSignOut, onNavigate }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className="bg-white shadow-lg py-4">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <img
              className="h-8 w-auto sm:h-10"
              src="/static/images/r-warrior.png"
              alt="Reading Warriors Logo"
            />
            <span className="ml-2 sm:ml-3 text-lg sm:text-xl font-bold text-blue-600">Reading Warriors</span>
          </div>
          <div className="hidden sm:flex items-center space-x-4">
        <button onClick={() => onNavigate('home')} className="text-gray-800 hover:text-blue-600">Home</button>
        <button onClick={() => onNavigate('blog')} className="text-gray-800 hover:text-blue-600">Free Stories</button>
        {user ? (
          <>
            <UserProfile />
            <button onClick={() => onNavigate('profile-select')} className="text-gray-800 hover:text-blue-600">Switch Profile</button>
            <button
              onClick={onSignOut}
              className="ml-4 px-4 py-2 bg-yellow-500 text-white rounded-full hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-opacity-50 transition duration-300"
            >
              Logout
            </button>
          </>
        ) : (
          <button onClick={() => onNavigate('login')} className="text-gray-800 hover:text-blue-600">Login</button>
        )}
      </div>
          <div className="sm:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="text-gray-500 hover:text-gray-600 focus:outline-none focus:text-gray-600"
              aria-label="Toggle menu"
            >
              <svg viewBox="0 0 24 24" className="h-6 w-6 fill-current">
                <path fillRule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
      {isMenuOpen && (
        <div className="sm:hidden mt-4 px-4">
          <div className="flex flex-col space-y-2">
            <button onClick={() => { onNavigate('home'); setIsMenuOpen(false); }} className="text-gray-800 hover:text-blue-600">Home</button>
            <button onClick={() => { onNavigate('blog'); setIsMenuOpen(false); }} className="text-gray-800 hover:text-blue-600">Free Stories</button>
            {user ? (
              <>
                <UserProfile />
                <button
                  onClick={() => { onSignOut(); setIsMenuOpen(false); }}
                  className="mt-2 px-4 py-2 bg-yellow-500 text-white rounded-full hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-opacity-50 transition duration-300"
                >
                  Close Book
                </button>
              </>
            ) : (
              <button onClick={() => { onNavigate('login'); setIsMenuOpen(false); }} className="text-gray-800 hover:text-blue-600">Login</button>
            )}
          </div>
        </div>
      )}
    </header>
  );
}

function AppContent() {
  const { user, userData, isLoading, refreshUserData } = useAuth();
  const [currentPage, setCurrentPage] = useState('home');
  const [selectedStoryId, setSelectedStoryId] = useState(null);
  const [selectedProfileId, setSelectedProfileId] = useState(null);

  useEffect(() => {
    if (!isLoading) {
      if (user && (!userData || !userData.childProfiles || userData.childProfiles.length === 0)) {
        setCurrentPage('profile-select');
      } else if (user && !selectedProfileId) {
        setCurrentPage('profile-select');
      } else if (!user) {
        setCurrentPage('home');
      }
    }
  }, [user, userData, isLoading, selectedProfileId]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setCurrentPage('home');
      setSelectedProfileId(null);
      console.log('Sign out successful');
    } catch (error) {
      console.error('Error signing out:', error.message);
    }
  };

  const handleNavigate = (page, storyId = null) => {
    setCurrentPage(page);
    if (storyId) {
      setSelectedStoryId(storyId);
    }
  };

  const handleProfileSelect = (profileId) => {
    setSelectedProfileId(profileId);
    setCurrentPage('story-generator');
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleBackToProfiles = () => {
    setCurrentPage('profile-select');
    setSelectedProfileId(null);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 via-green-100 to-yellow-100">
      <Helmet>
        <title>Reading Warriors - AI-Generated Stories for Kids</title>
        <meta name="description" content="Discover a world of AI-generated stories for kids at Reading Warriors. Engage your child with personalized, educational tales that spark imagination and foster a love for reading." />
      </Helmet>
      <Header user={user} onSignOut={handleSignOut} onNavigate={handleNavigate} />
      <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="px-4 py-6 sm:px-0">
          {currentPage === 'home' && !user && <LandingPage onStartAdventure={() => handleNavigate('login')} />}
          {currentPage === 'login' && <Login onLoginSuccess={() => refreshUserData(true)} />}
          {currentPage === 'profile-select' && <ProfileSelector onProfileSelect={handleProfileSelect} />}
          {currentPage === 'story-generator' && <StoryGenerator
              profileId={selectedProfileId}
              onBackToProfiles={handleBackToProfiles}
            />}
          {currentPage === 'blog' && <BlogPage onSelectStory={(storyId) => handleNavigate('story', storyId)} />}
          {currentPage === 'story' && <StoryPage storyId={selectedStoryId} onBack={() => handleNavigate('blog')} />}
        </div>
      </main>
      <InstallPWA />
    </div>
  );
}

function App() {
  return (
    <HelmetProvider>
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </HelmetProvider>
  );
}

export default App;