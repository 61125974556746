import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthContext.js';
import { incrementStoriesGenerated, canGenerateStory, checkSubscriptionStatus, updateChildProfilePoints } from './userFunctions.js';
import SubscriptionButton from './SubscriptionButton.js';
import WarriorLoadingAnimation from './WarriorLoadingAnimation.js';

const StoryGenerator = ({ profileId, onBackToProfiles }) => {
  const { user, userData, refreshUserData } = useAuth();
  const [isBannerExpanded, setIsBannerExpanded] = useState(false);
  const [currentProfile, setCurrentProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [storyCategory, setStoryCategory] = useState('');
  const [age] = useState('');
  const [storyDescription, setStoryDescription] = useState('');
  const [paragraphs, setParagraphs] = useState('5');
  const [story, setStory] = useState([]);
  const [canGenerate, setCanGenerate] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentView, setCurrentView] = useState('form');
  const [pagesRead, setPagesRead] = useState(0);
  const [earnedPoints, setEarnedPoints] = useState(0);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [generatedTitle, setGeneratedTitle] = useState('');
  const [isGeneratingStory, setIsGeneratingStory] = useState(false);
  const [showFireworks, setShowFireworks] = useState(false);
  const [sessionPoints, setSessionPoints] = useState(0);
  const [totalPoints, setTotalPoints] = useState(0);

  const storyCategories = [
    'Adventure', 'Sports', 'Mystery', 'Scary', 'Fairy Tale',
    'Historical', 'Educational', 'Humor', 'Animal Stories', 'Superhero'
  ];

  useEffect(() => {
    const loadData = async () => {
      if (user) {
        try {
          await refreshUserData();
          setIsDataLoaded(true);
          setIsLoading(false);
        } catch (error) {
          console.error('Error refreshing user data:', error);
          setError('Failed to load user data. Please try again.');
          setIsLoading(false);
        }
      }
    };

    loadData();
  }, [user, refreshUserData]);

  useEffect(() => {
    if (userData && profileId) {
      const profile = userData.childProfiles.find(p => p.id === profileId);
      setCurrentProfile(profile);
    }
  }, [userData, profileId]);

  useEffect(() => {
    if (userData && userData.childProfiles) {
      const currentProfile = userData.childProfiles.find(profile => profile.id === profileId);
      if (currentProfile) {
        setTotalPoints(currentProfile.points || 0);
      }
    }
  }, [userData, profileId]);

  useEffect(() => {
    const checkGenerationStatus = async () => {
      if (user && profileId && userData) {
        try {
          const status = await canGenerateStory(user.uid);
          setCanGenerate(status);
        } catch (error) {
          console.error('Error checking generation status:', error);
          setError('An error occurred while checking story generation status. Please try again later.');
          setCanGenerate(false);
        }
      }
    };

    if (isDataLoaded && profileId) {
      checkGenerationStatus();
    }
  }, [user, profileId, userData, isDataLoaded]);

  const toggleBanner = () => {
    setIsBannerExpanded(prev => !prev);
  };

  const renderBanner = () => {
    return (
      <div className="mb-4 bg-white rounded-lg shadow-md overflow-hidden">
        <div
          className="flex justify-between items-center p-3 cursor-pointer"
          onClick={toggleBanner}
        >
          <div className="flex items-center space-x-2">
            <h2 className="text-lg font-bold text-blue-600">Reading Warriors</h2>
            <h3 className="text-sm font-semibold text-blue-500">
              {currentProfile ? `${currentProfile.name}'s Adventure` : 'Adventure'}
            </h3>
          </div>
          {isBannerExpanded ? (
            <span className="text-blue-500">▲</span>
          ) : (
            <span className="text-blue-500">▼</span>
          )}
        </div>
        {isBannerExpanded && (
          <div className="px-3 pb-3 text-sm">
            <p className="text-green-600">Current Points: {currentProfile?.points || 0}</p>
            <p className="text-blue-600">
              Total Quests generated: {userData?.storiesGenerated} / {userData?.subscriptionStatus === 'active' ? 'Unlimited' : '3'}
            </p>
          </div>
        )}
      </div>
    );
  };

  const generateStory = async (e) => {
    e.preventDefault();
    setIsGeneratingStory(true);
    setIsLoading(true);
    setError('');
    setStory([]);
    setPagesRead(0);
    setEarnedPoints(0);
    setCurrentView('loading');
    setCurrentPage(0);

    try {
      if (!user || !profileId) {
        throw new Error('User is not authenticated or no profile is selected');
      }

      const canGenerate = await canGenerateStory(user.uid);
      if (!canGenerate) {
        throw new Error('You have reached your story generation limit. Please subscribe to generate more stories.');
      }

      const response = await fetch('/api/generate-story', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: user.uid,
          profileId: profileId,
          storyCategory,
          age: parseInt(age),
          paragraphs: parseInt(paragraphs),
          readingLevel: currentProfile.readingLevel,
          storyDescription: storyDescription
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.story && data.title) {
        const storyParagraphs = data.story.split('\n\n').filter(para => para.trim() !== '');
        setStory(storyParagraphs);
        setGeneratedTitle(data.title);
      } else {
        throw new Error('No story content or title received from the server');
      }

      setIsLoading(false);
      setIsGeneratingStory(false);

      await incrementStoriesGenerated(user.uid);
      await refreshUserData();
      await checkSubscriptionStatus(user.uid);
      const newCanGenerate = await canGenerateStory(user.uid);
      setCanGenerate(newCanGenerate);
      setCurrentView('story');
    } catch (error) {
      console.error('Error in generateStory:', error);
      setError(error.message || 'Failed to generate story. Please try again.');
      setCurrentView('form');
    } finally {
      setIsLoading(false);
      setIsGeneratingStory(false);
    }
  };

  const nextPage = async () => {
    if (currentPage < story.length - 1) {
      setCurrentPage(currentPage + 1);
      setPagesRead(pagesRead + 1);
      const pointsEarned = 10; // Assume 10 points per page read
      setEarnedPoints(earnedPoints + pointsEarned);
      setSessionPoints(sessionPoints + pointsEarned);
      setTotalPoints(totalPoints + pointsEarned);

      try {
        await updateChildProfilePoints(user.uid, profileId, pointsEarned);
        await refreshUserData();
      } catch (error) {
        console.error('Error updating child profile points:', error);
      }
    } else {
      finishStory();
    }
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const finishStory = async () => {
    setPagesRead(pagesRead + 1);
    const finalPointsEarned = 10; // Points for the last page
    setEarnedPoints(earnedPoints + finalPointsEarned);
    setSessionPoints(sessionPoints + finalPointsEarned);
    setTotalPoints(totalPoints + finalPointsEarned);
    setCurrentView('results');
    setShowFireworks(true);

    if (user) {
      try {
        await updateChildProfilePoints(user.uid, profileId, finalPointsEarned);
        await refreshUserData();
      } catch (error) {
        console.error('Error updating child profile points:', error);
      }
    }
    // Hide fireworks after 3 seconds
    setTimeout(() => setShowFireworks(false), 3000);
  };

  const renderPointsDisplay = () => (
    <div className="fixed top-0 right-0 m-4 p-2 bg-yellow-400 text-yellow-900 rounded-lg shadow-lg z-50 text-sm sm:text-base">
      <div className="font-bold">Total Points: {totalPoints}</div>
      <div>Session Points: +{sessionPoints}</div>
    </div>
  );

  const renderStoryForm = () => {
    return (
      <form onSubmit={generateStory} className="space-y-4">
        <div>
          <label htmlFor="storyCategory" className="block text-sm font-medium text-gray-700">Story Category</label>
          <select
            id="storyCategory"
            value={storyCategory}
            onChange={(e) => setStoryCategory(e.target.value)}
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            required
          >
            <option value="">Select a category</option>
            {storyCategories.map((category) => (
              <option key={category} value={category}>{category}</option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="paragraphs" className="block text-sm font-medium text-gray-700">Number of Paragraphs</label>
          <input
            type="number"
            id="paragraphs"
            value={paragraphs}
            onChange={(e) => setParagraphs(e.target.value)}
            min="3"
            max="15"
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            required
          />
        </div>
        <div>
          <label htmlFor="storyDescription" className="block text-sm font-medium text-gray-700">Story Description (Optional)</label>
          <textarea
            id="storyDescription"
            value={storyDescription}
            onChange={(e) => setStoryDescription(e.target.value)}
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            rows="4"
            placeholder="Describe the story you want (e.g., characters, setting, plot ideas)"
          />
        </div>
        <button
          type="submit"
          disabled={!canGenerate || isGeneratingStory}
          className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
            (!canGenerate || isGeneratingStory) && 'opacity-50 cursor-not-allowed'
          }`}
        >
          {isGeneratingStory ? 'Generating Story...' : 'Generate Story'}
        </button>
      </form>
    );
  };


  const renderStory = () => {
    return (
      <div className="space-y-4">
        <h2 className="text-2xl font-bold text-center">{generatedTitle}</h2>
        <p>{story[currentPage]}</p>
        <div className="flex justify-between">
          <button
            onClick={prevPage}
            disabled={currentPage === 0}
            className="px-4 py-2 bg-blue-500 text-white rounded disabled:opacity-50"
          >
            Previous
          </button>
          <button
            onClick={nextPage}
            className="px-4 py-2 bg-blue-500 text-white rounded"
          >
            {currentPage === story.length - 1 ? 'Finish' : 'Next'}
          </button>
        </div>
      </div>
    );
  };

  const renderResults = () => {
    return (
      <div className="text-center space-y-4">
        <h2 className="text-2xl font-bold">Great job, {currentProfile?.name}!</h2>
        <p>You've read {pagesRead} pages and earned {earnedPoints} points!</p>
        <button
          onClick={() => setCurrentView('form')}
          className="px-4 py-2 bg-blue-500 text-white rounded"
        >
          Generate Another Story
        </button>
        {showFireworks && <div className="fireworks">🎉</div>}
      </div>
    );
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  const renderContent = () => {
    switch (currentView) {
      case 'form':
        return renderStoryForm();
      case 'loading':
        return <WarriorLoadingAnimation />;
      case 'story':
        return renderStory();
      case 'results':
        return renderResults();
      default:
        return null;
    }
  };

  return (
    <div className="max-w-2xl mx-auto p-4 relative">
      {renderPointsDisplay()}
      <button
        onClick={onBackToProfiles}
        className="mb-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
      >
        Back to Profiles
      </button>
      {renderBanner()}
      {renderContent()}
      {!canGenerate && <SubscriptionButton />}
    </div>
  );
};

export default StoryGenerator;