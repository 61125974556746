import React from 'react';
import { Helmet } from 'react-helmet';
import './StoryPage.css';

const stories = [
  {
    id: 'the-littlest-bomber',
    title: 'The Littlest Bomber',
    coverImage: '/static/images/Bombers_Thumbnail.png',
    content: `
      <h1>The Littlest Bomber</h1>
      <p>Tommy was the youngest player on the Bombers, his local Little League baseball team. At just seven years old, he was a full year younger than most of his teammates. Despite his small size, Tommy loved baseball with all his heart and never missed a practice. Coach Johnson always made sure Tommy got to play in every game, even if it was just for an inning or two. Tommy usually played right field, where he'd stand patiently, hoping the ball would come his way. Most of the time, it didn't, but that never dampened his enthusiasm.</p>
      <p>One sunny Saturday afternoon, the Bombers were playing their biggest rivals, the Eagles, in the championship game. The stands were packed with excited parents and siblings, all cheering loudly. Tommy started the game on the bench, eagerly watching his teammates play. By the bottom of the ninth inning, the Bombers were down by one run. Coach Johnson called Tommy over.</p>
      <p>"Alright, Tommy," he said with a smile, "I need you to pinch hit. Remember what we practiced, keep your eye on the ball, and do your best."</p>
      <p>Tommy nodded nervously, his heart pounding as he picked up a bat that seemed almost too big for him. As Tommy stepped up to the plate, he could hear his parents cheering from the stands. The Eagles' pitcher looked huge to Tommy, but he stood his ground, remembering everything Coach had taught him.</p>
      <p>The first pitch whizzed by – "Strike one!" called the umpire. Tommy took a deep breath. The second pitch came in – "Ball one!" Tommy watched carefully as the third pitch approached. With all his might, he swung the bat.</p>
      <p>CRACK! The sound echoed across the field as the ball soared through the air. Tommy stood in shock for a moment before he heard his teammates yelling, "Run, Tommy, run!" His little legs carried him as fast as they could. He rounded first base, then second. The ball was still rolling in the outfield.</p>
      <p>As Tommy approached third base, he saw Coach Johnson waving him home. With one final burst of speed, Tommy slid into home plate just as the ball came in.</p>
      <p>"Safe!" yelled the umpire.</p>
      <p>The crowd erupted in cheers as Tommy's teammates swarmed him. The Bombers had won the championship, and the littlest player had become the biggest hero of the day.</p>
    `
  },
  {
    id: 'the-whispering-cornfield',
    title: 'The Whispering Cornfield',
    coverImage: '/static/images/Cornfield_Thumbnail.png',
    content: `
      <h1>The Whispering Cornfield</h1>
      <p>Emma loved visiting her grandparents' farm every summer. The wide-open fields, the smell of fresh hay, and Grandma's delicious apple pie made it a perfect getaway. This year, however, things felt different from the moment she arrived.</p>
      <p>As she stepped out of the car, a chilly breeze rustled through the cornfield, carrying whispers that sounded almost like her name. Emma shrugged it off, thinking it was just her imagination. That night, as she lay in her cozy attic bedroom, she heard soft tapping on her window. When she peeked outside, she saw nothing but the moonlit cornfield swaying gently in the wind.</p>
      <p>The next day, Emma decided to explore the barn. As she pushed open the heavy wooden door, she noticed strange symbols carved into the walls. They looked ancient and mysterious. Grandpa appeared behind her, startling her. "Oh, those old things?" he chuckled. "They've been here since I was a boy. Some say they keep the farm safe." Emma nodded, but couldn't shake the feeling that the symbols were watching her.</p>
      <p>Later that afternoon, Emma ventured into the cornfield. The tall stalks towered above her, creating a maze of green. As she walked deeper, she realized the whispering she'd heard earlier was getting louder. It almost sounded like a conversation, but she couldn't make out the words. Suddenly, she came across a small clearing where the corn had been flattened into a perfect circle. In the center lay a single, ancient-looking coin.</p>
      <p>Heart pounding, Emma picked up the coin. As soon as her fingers touched it, the whispering stopped. An eerie silence fell over the cornfield. She looked up to see a path had opened in the corn, leading straight back to the farmhouse. Emma ran, clutching the coin tightly.</p>
      <p>That night, as she showed her grandparents the coin, they exchanged knowing glances. "Well," Grandma said with a twinkle in her eye, "looks like the farm has chosen its next guardian." Emma's eyes widened as her grandparents began to tell her about the magic that had protected their land for generations, and how she was now part of that ancient legacy.</p>
    `
  },
  {
    "id": "lilys-big-flip",
    "title": "Lily's Big Flip",
    "coverImage": "/static/images/gymnastics.png",
    "excerpt": "A heartwarming tale of a young gymnast's first big performance.",
    "content": `
      <h1>Lily's Big Flip</h1>
      <p>Lily was a bubbly 5-year-old girl who loved to tumble and twirl. Every Tuesday and Thursday, she went to gymnastics class at the Sunshine Gym. She always wore her favorite purple leotard with sparkly stars.</p>
      <p>At gymnastics, Lily learned how to do cartwheels, somersaults, and handstands. Her teacher, Miss Sarah, was very kind and always helped Lily try new things. Lily's favorite part of class was jumping on the big, bouncy trampoline.</p>
      <p>One day, Miss Sarah told the class about a special gymnastics show. All the little gymnasts would get to show their families what they had learned. Lily was excited but also a little nervous. She had never performed in front of so many people before!</p>
      <p>Lily practiced hard for the show. She stretched every morning and did her somersaults in the backyard. Her mom and dad cheered her on, and her little brother tried to copy her moves.</p>
      <p>Finally, the day of the show arrived. Lily put on her purple leotard and her mom tied her hair in a neat bun. At the gym, there were colorful balloons and lots of parents sitting in chairs. Lily's tummy felt fluttery, like there were butterflies inside.</p>
      <p>When it was Lily's turn, she took a deep breath and smiled big. She did a perfect cartwheel, just like Miss Sarah taught her. Then, she did three somersaults in a row! For her final trick, Lily did a big jump on the trampoline and landed on her feet. Everyone clapped and cheered. Lily felt proud and happy. She had done her very best, and that made her feel like a true gymnastics star!</p>
    `
  },
  {
    "id": "time-traveling-treehouse",
    "title": "The Time-Traveling Treehouse",
    "coverImage": "/static/images/treehouse.png",
    "excerpt": "An exciting adventure through time in a magical treehouse that appears overnight.",
    "content": `
      <h1>The Time-Traveling Treehouse</h1>
      <p>Max couldn't believe his eyes when he saw the treehouse in his backyard. It definitely wasn't there yesterday! The old oak tree had sprouted this wooden wonderland overnight, complete with a rope ladder and a sign that read "Adventure Awaits!"</p>
      <p>Curiosity got the better of Max, and he climbed up. Inside, he found a cozy room with bookshelves, bean bags, and a strange-looking contraption in the corner. It had a big, shiny lever and a dial with dates on it. Without thinking twice, Max pulled the lever.</p>
      <p>The treehouse began to shake and spin. When it stopped, Max peeked out the window and gasped. The backyard was gone! Instead, he saw a landscape filled with towering dinosaurs munching on enormous ferns. He had traveled back in time to the age of dinosaurs!</p>
      <p>Max spent the afternoon watching gentle giants wade through swamps and pterodactyls soar overhead. He even found a dinosaur egg and carefully returned it to its nest. As the sun began to set, Max knew it was time to head home. He pulled the lever again, and whoosh! The treehouse spun back to the present.</p>
      <p>The next day, Max invited his best friend Sarah to see the treehouse. This time, they turned the dial to the year 3000. In a flash, they were surrounded by flying cars and robots walking dogs in a park. They played with gadgets that could materialize any food they wanted and rode on a giant slide that looped around skyscrapers.</p>
      <p>Every day after school, Max and Sarah would race to the treehouse, eager to explore a new time period. They watched pyramids being built in ancient Egypt, cheered on knights at a medieval tournament, and even glimpsed woolly mammoths in the Ice Age.</p>
      <p>But their favorite adventure was the day they traveled 50 years into the future and met their grown-up selves. Future Max was an astronaut, and future Sarah was the president! They told the kids to keep dreaming big and never stop exploring.</p>
      <p>As weeks went by, Max and Sarah's parents wondered why they were so excited about a plain old treehouse. Little did they know that inside those wooden walls, a time machine was taking their children on incredible journeys through history and into the future.</p>
      <p>Max and Sarah promised to keep the time-traveling treehouse a secret. After all, some adventures are too amazing to explain – they have to be experienced! And with the whole of time and space to explore, they knew their after-school hours would never be boring again.</p>
    `
  },
  {
    id: 'the-melody-maker',
    title: 'The Melody Maker',
    coverImage: '/static/images/melody_maker.png',
    excerpt: 'A young girl discovers her magical musical talent and brings harmony to her town.',
    content: `
      <h1>The Melody Maker</h1>
      <p>In a small town nestled between rolling hills, there lived a quiet girl named Melody. She had wild curly hair and eyes that sparkled like starlight. Melody loved music more than anything in the world, but she was too shy to sing or play an instrument in front of others.</p>
      <p>One day, while exploring the attic of her grandmother's old house, Melody found a peculiar flute hidden in a dusty trunk. It was made of shimmering silver and had tiny musical notes etched all along its surface. When she blew into it, the most beautiful sound she had ever heard filled the air.</p>
      <p>To Melody's amazement, as the sound echoed through the house, the drooping flowers in the vase perked up, the creaky floorboards stopped squeaking, and even the grumpy old cat started purring contentedly. Melody realized this was no ordinary flute – it had the power to bring harmony and joy to everything around it.</p>
      <p>Excited by her discovery, Melody began practicing with the flute every day. She played for the plants in her garden, and they grew taller and more colorful. She played for the birds, and they sang along in perfect harmony. Even the cranky neighbor's dog stopped barking and wagged its tail to the beat.</p>
      <p>As Melody's confidence grew, she decided to share her gift with the town. One sunny afternoon, she stood in the town square and began to play. The sweet melody floated through the air, touching everyone who heard it. People stopped their hurried walks and smiled. Children started dancing in the street. The grouchy baker who never smiled began humming along as he handed out free cookies to passersby.</p>
      <p>Soon, the whole town was filled with music and laughter. People who had argued for years made up, lonely elders found new friends, and the entire community came together in a joyful celebration. Melody's music had brought out the best in everyone.</p>
      <p>From that day on, Melody was known as the Melody Maker. She played at every town event, in schools, and in hospitals, spreading happiness wherever she went. The once-quiet girl had found her voice through music and discovered that sometimes the most powerful melodies come from the heart.</p>
      <p>As years passed, Melody taught other children how to play instruments and sing. She started a town orchestra where everyone, regardless of their musical skills, was welcome to join. The small town became famous for its music and the incredible harmony among its people.</p>
      <p>Melody had learned that a little bit of magic and a lot of music could change the world, one note at a time. And every time she played her silver flute, she remembered that the most beautiful symphonies often start with a single, courageous melody.</p>
    `
  },
  {
    id: 'the-underdogs-slam-dunk',
    title: "The Underdogs' Slam Dunk",
    coverImage: '/static/images/basketball_team.png',
    content: `
      <h1>The Underdogs' Slam Dunk</h1>
      <p>In the small town of Hoopsville, where basketball was king, there was a group of boys who loved the game but had never quite fit in with the school teams. There was lanky Larry, who was all arms and legs; Shorty Sam, who barely reached the other boys' shoulders; Clumsy Carl, who could trip over his own shoelaces; and Wheezy Wes, who got out of breath just tying his shoes.</p>
      <p>One day, they decided to form their own team, calling themselves "The Underdogs." At first, everyone in town laughed. How could these misfits ever hope to play real basketball?</p>
      <p>But The Underdogs were determined. They found an old, unused court behind the library and started practicing every day after school. They each brought something special to the team: Larry's long arms were great for blocking shots, Sam's small size made him quick and agile, Carl's clumsiness disappeared when he had a ball in his hands, and Wes, well, he became the world's best cheerleader and strategist from the bench.</p>
      <p>As weeks went by, something magical started happening. The boys began to work together, each using their unique skills to complement the others. Larry and Sam developed an unstoppable alley-oop combo. Carl's unconventional moves baffled defenders. And Wes's enthusiastic cheering and clever plays from the sidelines boosted the team's spirit.</p>
      <p>Word spread about the odd little team that practiced behind the library. Soon, curious onlookers started gathering to watch them play. Among them was Coach Johnson, who had once played professional basketball.</p>
      <p>Impressed by their teamwork and determination, Coach Johnson offered to train The Underdogs. Under his guidance, they improved rapidly. He taught them that basketball wasn't just about being the tallest or the fastest – it was about working together and playing smart.</p>
      <p>As the town's annual basketball tournament approached, The Underdogs decided to enter. Everyone was skeptical, but the boys were excited for the chance to show what they could do.</p>
      <p>In their first game, The Underdogs surprised everyone by holding their own against a much taller team. Larry's blocks, Sam's quick steals, and Carl's unpredictable shots kept them in the game. With seconds left and down by one point, Wes called a timeout and drew up a play in the dust.</p>
      <p>The buzzer sounded, and The Underdogs executed Wes's play perfectly. Sam stole the ball, passed it to Carl who fumbled but managed to tap it to Larry. Larry, surrounded by defenders, spotted Sam who had run to the other end of the court. With a massive leap, Larry threw the ball the entire length of the court. Sam caught it and sank a layup just as the final whistle blew.</p>
      <p>The crowd went wild! The Underdogs had won their first tournament game. Though they didn't win the championship, they made it to the semi-finals, farther than anyone had expected. More importantly, they had won the respect and admiration of the entire town.</p>
      <p>From that day on, The Underdogs were no longer seen as misfits. They had shown everyone that with teamwork, determination, and belief in themselves, anything was possible. And in Hoopsville, they became legends – the team that proved that in basketball and in life, it's not about fitting in, but about finding where you belong.</p>
    `
  },
  {
    id: 'the-whispering-woods',
    title: 'The Whispering Woods',
    coverImage: '/static/images/whispering_woods.png',
    excerpt: 'Two brave friends uncover the secret of a mysterious forest and help a lost spirit find its way home.',
    content: `
      <h1>The Whispering Woods</h1>
      <p>On the outskirts of Millbrook town stood the Whispering Woods. Local legend said that on moonlit nights, you could hear faint whispers echoing through the trees. Most folks avoided the woods after dark, but 11-year-old Jamie and her best friend Alex were too curious to stay away.</p>
      <p>One crisp autumn evening, as the full moon rose, Jamie and Alex decided to investigate the woods. Armed with flashlights and walkie-talkies, they entered the forest, dry leaves crunching beneath their feet.</p>
      <p>At first, everything seemed normal. Owls hooted, and crickets chirped. But as they ventured deeper, the regular forest sounds faded away, replaced by an eerie silence. Then, they heard it – a faint whisper carried on the wind.</p>
      <p>"Did you hear that?" Jamie whispered, her flashlight beam darting between the trees.</p>
      <p>Alex nodded, his eyes wide. "It sounded like... someone calling for help."</p>
      <p>They pressed on, the whispers growing louder. Suddenly, Jamie's flashlight flickered and went out. Alex's followed suit, plunging them into darkness. The only light came from the full moon filtering through the leaves above.</p>
      <p>Heart pounding, Jamie reached for Alex's hand. As their fingers touched, a soft blue glow appeared in the distance. Curious, they moved towards it.</p>
      <p>The glow led them to a small clearing where they saw the source of the light – a translucent, shimmering figure of a young girl, dressed in old-fashioned clothes.</p>
      <p>Jamie and Alex gasped, but surprisingly, they weren't scared. The ghost-girl looked sad and lost, not menacing.</p>
      <p>"Can you help me?" the spirit asked, her voice like a gentle breeze. "I've been lost for so long."</p>
      <p>Gathering their courage, Jamie and Alex approached. The ghost-girl told them she had gotten lost in the woods a long time ago and couldn't find her way home. She had been trying to call for help ever since, her whispers carrying through the woods on moonlit nights.</p>
      <p>Jamie had an idea. She pulled out an old compass her grandfather had given her. "Maybe this can help you find your way," she said, holding it out.</p>
      <p>As the ghost-girl touched the compass, it began to spin wildly before pointing firmly east. The girl's face lit up with joy.</p>
      <p>"I remember now! My home is that way, just beyond the woods. Thank you!"</p>
      <p>With a grateful smile, the ghost-girl began to fade, the blue glow growing fainter until it disappeared entirely. As she vanished, Jamie and Alex's flashlights flickered back to life.</p>
      <p>Amazed by their adventure, the two friends made their way out of the woods. From that night on, the whispers in the Whispering Woods were never heard again.</p>
      <p>Jamie and Alex kept their incredible experience a secret, but they often returned to the woods, no longer afraid. Instead, they felt a sense of peace, knowing they had helped a lost soul find her way home.</p>
      <p>As for the compass, it now hangs on Jamie's wall – a reminder that with courage, kindness, and a little bit of magic, even the scariest situations can lead to amazing adventures.</p>
    `
  },
  {
    id: 'the-spirit-squad-surprise',
    title: 'The Spirit Squad Surprise',
    coverImage: '/static/images/spirit_squad.png',
    excerpt: 'A shy girl finds her voice and helps her cheerleading team create an unforgettable routine.',
    content: `
      <h1>The Spirit Squad Surprise</h1>
      <p>Zoe had always been the quietest girl in her class. She loved to dance and tumble in the privacy of her room, but the thought of performing in front of others made her stomach do flips. So when her best friend Mia suggested they try out for the Sunnyville Middle School Spirit Squad, Zoe's first instinct was to say no.</p>
      <p>"Come on, Zoe! It'll be fun," Mia encouraged. "We can do it together!"</p>
      <p>Reluctantly, Zoe agreed. To her surprise, they both made the team. As practices began, Zoe discovered she enjoyed the routines and the feeling of being part of a group. But whenever Coach Lisa asked for ideas, Zoe stayed silent, too shy to speak up.</p>
      <p>The biggest event of the year was approaching – the Sunnyville Town Festival. The Spirit Squad was set to perform, and everyone was buzzing with excitement. Everyone except Zoe, who was nervous about performing in front of the whole town.</p>
      <p>One day after practice, Zoe overheard some of her teammates talking. "Our routine is good, but it's missing something," one girl said. "We need a big finish to really wow the crowd."</p>
      <p>Zoe had an idea – a daring move she'd been practicing at home. But the thought of suggesting it made her palms sweat. That night, she couldn't sleep, tossing and turning as she imagined herself speaking up.</p>
      <p>The next day, Zoe arrived at practice early. Taking a deep breath, she approached Coach Lisa. "Um, Coach?" she said softly. "I have an idea for the finale."</p>
      <p>Coach Lisa's face lit up. "That's great, Zoe! Let's see it!"</p>
      <p>With shaky legs, Zoe demonstrated her move – a back handspring into a split leap. The whole team watched in awe.</p>
      <p>"Zoe, that's amazing!" Mia cheered.</p>
      <p>Coach Lisa was thrilled. "This is exactly what we needed! Zoe, would you be willing to teach the move to the other girls who can do back handsprings?"</p>
      <p>For the next two weeks, Zoe found herself at the center of attention as she helped her teammates perfect the move. She discovered that she loved teaching and encouraging others. Her confidence grew with each practice.</p>
      <p>Finally, the day of the festival arrived. As the Spirit Squad took their places on the town square, Zoe's heart raced. The music started, and they began their routine. With each cheer and tumble, Zoe felt her nervousness melt away, replaced by pure joy.</p>
      <p>As the finale approached, Zoe caught Mia's eye. They shared a smile before launching into the big finish. The crowd gasped as half the team executed Zoe's move in perfect synchronization. As they struck their final pose, the town erupted in cheers.</p>
      <p>Later, as the team celebrated their success, Coach Lisa approached Zoe. "I'm so proud of you," she said. "Not just for the amazing move, but for finding the courage to share your ideas. You've really grown, Zoe."</p>
      <p>Zoe beamed with pride. She realized that she had found more than just a team – she had found her voice and a new confidence that would stay with her long after the cheers faded.</p>
      <p>From that day on, Zoe approached challenges with a new spirit. She knew that with hard work, support from friends, and the courage to speak up, she could overcome any obstacle – both on and off the cheerleading mat.</p>
    `
  },
  {
    id: 'whispers-and-wishes',
    title: 'Whispers and Wishes',
    coverImage: '/static/images/first_horse.png',
    excerpt: 'A young girls dream comes true when she gets her first horse, but she learns that owning a horse is about more than just riding.',
    content: `
      <h1>Whispers and Wishes</h1>
      <p>For as long as she could remember, 12-year-old Lily had dreamed of having her own horse. Every birthday candle, every falling star, every dandelion seed carried the same wish: "Please, let me have a horse of my own."</p>
      <p>Lily's parents knew of her dream but always said the same thing: "Horses are a big responsibility, Lily. Maybe when you're older." But Lily was determined to prove she was ready.</p>
      <p>She started volunteering at the local stable every weekend, mucking out stalls, grooming horses, and helping with feeding time. She read every book she could find about horse care and spent hours practicing her riding skills during lessons.</p>
      <p>On the morning of her 13th birthday, Lily's parents woke her up early. "We have a surprise for you," they said, barely containing their excitement. They drove to the stable where Lily volunteered, and her heart began to race.</p>
      <p>As they walked towards the pasture, Lily saw a beautiful dapple-gray mare grazing peacefully. The stable owner, Ms. Thompson, was standing nearby with a bright red bow in her hand.</p>
      <p>"Happy birthday, Lily," Ms. Thompson said with a warm smile. "Meet Whisper. She's yours."</p>
      <p>Lily couldn't believe her ears. She approached Whisper slowly, her hand outstretched. The mare lifted her head, her soft brown eyes meeting Lily's. Gently, Lily stroked Whisper's velvety nose, and the horse nickered softly, as if saying hello.</p>
      <p>"She's perfect," Lily whispered, tears of joy in her eyes.</p>
      <p>But owning Whisper wasn't all galloping through fields and winning ribbons. Lily quickly learned that horse ownership was hard work. She woke up early every morning to feed and groom Whisper before school. Weekends were filled with mucking out stalls, cleaning tack, and making sure Whisper got plenty of exercise.</p>
      <p>There were challenging days too. Once, Whisper got spooked during a ride and Lily fell off, spraining her wrist. Another time, Whisper developed colic, and Lily stayed up all night with her, walking her around the paddock until the vet arrived.</p>
      <p>Through it all, the bond between Lily and Whisper grew stronger. Lily learned to read Whisper's moods, knowing when she was happy, nervous, or not feeling well. And Whisper seemed to understand Lily too, always greeting her with a soft nicker and gently nuzzling her shoulder.</p>
      <p>One day, as Lily was brushing Whisper's mane, her mother came to watch. "You know, Lily," she said, "when you first asked for a horse, we weren't sure you understood how much work it would be. But watching you with Whisper, we can see how much you've grown. We're so proud of you."</p>
      <p>Lily smiled, giving Whisper an affectionate pat. "Thanks, Mom. Whisper is more than just my horse. She's my best friend, my teacher, and my responsibility. She's taught me so much about patience, hard work, and love."</p>
      <p>As if understanding, Whisper turned her head and gently lipped at Lily's pocket, where she knew Lily kept apple slices for treats. Lily laughed and produced the treat, feeling a warmth in her heart as Whisper munched happily.</p>
      <p>That night, as Lily lay in bed, she realized something. For the first time in years, she didn't have to wish for a horse anymore. Instead, she made a new wish: to be the best friend and caretaker Whisper could ever have. And as she drifted off to sleep, she could almost hear Whisper's soft whinny in the distance, as if the horse was wishing her sweet dreams too.</p>
    `
  },
  {
    id: 'secret-treehouse-laboratory',
    title: 'The Secret Treehouse Laboratory',
    coverImage: '/static/images/treehouse_lab.png',
    excerpt: 'Two adventurous boys turn their treehouse into a high-tech laboratory and end up saving their town.',
    content: `
      <h1>The Secret Treehouse Laboratory</h1>
      <p>Jack and his best friend Omar had the coolest treehouse in the neighborhood. But it wasn't just any treehouse - it was their secret laboratory. Both boys loved science and spent every spare moment tinkering with gadgets and conducting experiments.</p>
      <p>One day, while rummaging through a yard sale, they found an old computer. "We could use this for our lab!" Jack exclaimed. Omar grinned, already imagining the possibilities.</p>
      <p>Over the next few weeks, the boys worked tirelessly to transform their treehouse. They installed solar panels for power, set up the computer, and even created a small weather station using parts from old toys and kitchen appliances.</p>
      <p>"With this weather station," Omar explained, "we can predict when it's going to rain or snow!"</p>
      <p>Jack nodded excitedly. "And we can warn everyone in the neighborhood!"</p>
      <p>As summer turned to fall, Jack and Omar's weather predictions became more and more accurate. The neighbors started to rely on the boys' forecasts for planning barbecues and outdoor events.</p>
      <p>One crisp autumn day, while analyzing their weather data, Jack noticed something strange. "Omar, look at this," he said, pointing to the computer screen. "The temperature and pressure are dropping really fast."</p>
      <p>Omar's eyes widened. "That could mean a big storm is coming. But the local weather channel hasn't said anything about it."</p>
      <p>The boys decided to alert their parents, who initially seemed skeptical. "Are you sure about this?" Jack's mom asked. "The weather report says it'll be clear all week."</p>
      <p>But Jack and Omar were confident in their data. They convinced their parents to help them warn the neighborhood, just in case.</p>
      <p>As night fell, dark clouds began to gather. The wind picked up, howling through the trees. Suddenly, the sky opened up, releasing a torrent of rain and hail.</p>
      <p>Thanks to Jack and Omar's warning, everyone in the neighborhood had secured their outdoor furniture, brought in their pets, and prepared for the unexpected storm. The boys' treehouse laboratory, with its sturdy construction and weather-resistant modifications, stood strong against the wind and rain.</p>
      <p>The next morning, as everyone emerged to assess the damage, they were relieved to find that it was minimal. The storm had been intense, but short-lived.</p>
      <p>The mayor of the town visited Jack and Omar's treehouse. "Boys," she said, looking impressed, "your quick thinking and scientific know-how potentially saved our town from significant damage. How would you like to help set up an early warning system for the whole town?"</p>
      <p>Jack and Omar couldn't believe their ears. Their secret treehouse laboratory was about to become an official part of the town's safety infrastructure!</p>
      <p>As they got to work on their new project, Jack turned to Omar with a grin. "You know what this means, right? We're not just scientists now. We're superheroes!"</p>
      <p>Omar laughed and high-fived his friend. "Superhero scientists! I like the sound of that!"</p>
      <p>From that day on, Jack and Omar's love for science only grew stronger. They continued to learn, experiment, and use their knowledge to help their community. And their treehouse laboratory became a symbol of how curiosity, friendship, and a little bit of ingenuity could make the world a better place.</p>
    `
  },
  {
    id: 'sky-high-challenge',
    title: 'The Sky-High Challenge',
    coverImage: '/static/images/big_roller_coaster.png',
    excerpt: 'A young girl learns to face her fears and discovers the thrill of conquering a massive roller coaster.',
    content: `
      <h1>The Sky-High Challenge</h1>
      <p>Eleven-year-old Sophie stood at the entrance of Thrill World, the biggest amusement park in the state. Her heart raced, but not from excitement. There, looming in the distance, was The Thunderbolt - the tallest, fastest roller coaster she had ever seen.</p>
      <p>"Come on, Sophie!" her best friend Mia called, already running towards the massive steel structure. "We've been waiting all year for this!"</p>
      <p>Sophie's feet felt like lead. She had always been scared of heights and speed, preferring the gentle merry-go-round to the park's wilder rides. But this year, she had promised herself and Mia that she would finally brave The Thunderbolt.</p>
      <p>As they joined the line, Sophie's palms grew sweaty. She watched as the coaster zoomed by, filled with screaming riders. "Maybe this wasn't such a good idea," she muttered.</p>
      <p>Mia squeezed her hand. "You can do this, Sophie. Remember how scared you were of the Ferris wheel last year? And now you love it!"</p>
      <p>Sophie nodded, trying to draw courage from her friend's words. As they inched closer to the front of the line, she focused on her breathing, just like her mom had taught her when she felt nervous.</p>
      <p>Finally, it was their turn. Sophie climbed into the seat, her legs shaking. She pulled the safety bar down and gripped it tightly. The attendant checked their restraints, and suddenly, the car began to move.</p>
      <p>As they climbed the first hill, Sophie squeezed her eyes shut. The click-click-click of the track seemed to match her racing heartbeat. They reached the top, and for a moment, everything was still.</p>
      <p>Then, whoosh! They plummeted down the other side. Sophie's eyes flew open, and a scream escaped her lips. But as they zoomed through loops and corkscrews, something unexpected happened. The fear in her stomach turned into a flutter of excitement.</p>
      <p>Wind rushed through her hair as they sped along the track. Sophie found herself laughing, the thrill of the ride taking over. She threw her hands up in the air, just like Mia, feeling as if she were flying.</p>
      <p>All too soon, the ride came to an end. As they stepped off, Sophie's legs were wobbly, but this time from excitement rather than fear.</p>
      <p>"You did it!" Mia cheered, giving her a big hug. "I knew you could!"</p>
      <p>Sophie couldn't stop grinning. "That was... amazing! Can we go again?"</p>
      <p>As they raced back to the end of the line, Sophie realized something important. Being brave didn't mean not feeling scared. It meant facing your fears and trying anyway. And sometimes, on the other side of fear, you found something wonderful.</p>
      <p>For the rest of the day, Sophie and Mia rode The Thunderbolt again and again. With each ride, Sophie's confidence grew. She had faced her biggest fear and discovered a new love for roller coasters in the process.</p>
      <p>That night, as Sophie lay in bed, she thought about her adventure. She had learned that she was stronger and braver than she knew. And she couldn't wait to see what other challenges she could conquer.</p>
      <p>As she drifted off to sleep, Sophie smiled, already dreaming of her next big adventure. After all, if she could conquer The Thunderbolt, she could do anything she set her mind to.</p>
    `
  },
  {
    id: 'underdogs-touchdown',
    title: "The Underdog's Touchdown",
    coverImage: '/static/images/tackle_football.png',
    excerpt: 'A young boy leads his underdog team to victory in their first tackle football game of the season.',
    content: `
      <h1>The Underdog's Touchdown</h1>
      <p>Twelve-year-old Tyler nervously adjusted his helmet as he stood on the sidelines. It was the first game of the season for the Wildcats, and his first-ever tackle football game. The Wildcats were known as the underdogs of the league, having never won a single game last season.</p>
      <p>"Davidson! You're up!" Coach Martinez called out. Tyler's heart raced as he jogged onto the field, taking his position as running back.</p>
      <p>The Wildcats were playing against the Trojans, the league champions from last year. As Tyler looked across the line of scrimmage, he saw players who were bigger and more experienced than him. But he remembered what Coach always said: "It's not the size of the dog in the fight, it's the size of the fight in the dog."</p>
      <p>The first half of the game was tough. The Trojans scored two touchdowns, while the Wildcats struggled to make any significant gains. As the team huddled during halftime, Tyler could see the disappointment on his teammates' faces.</p>
      <p>"Listen up, Wildcats," Coach Martinez said, his voice firm but encouraging. "We're down, but we're not out. Remember your training, trust each other, and most importantly, believe in yourselves. Tyler, I want you to run the new play we've been practicing."</p>
      <p>Tyler nodded, a mix of excitement and nervousness bubbling in his stomach. The new play was risky, but if it worked, it could change the game.</p>
      <p>As the second half began, the Wildcats took the field with renewed determination. On their first possession, the quarterback handed the ball to Tyler. He tucked it close to his chest and sprinted towards the gap in the defensive line, just as they had practiced.</p>
      <p>Suddenly, a Trojan defender broke through. Tyler sidestepped, narrowly avoiding a tackle. He could hear the crowd cheering as he kept running, his legs pumping harder than ever before. Another defender dove for him, but Tyler leaped over him, maintaining his balance.</p>
      <p>The end zone was in sight. Tyler's lungs burned, but he pushed himself harder. He could hear footsteps behind him, getting closer. With one final burst of speed, Tyler crossed into the end zone just as a defender's hand grazed his jersey.</p>
      <p>Touchdown!</p>
      <p>The crowd erupted in cheers. Tyler's teammates swarmed him, patting his helmet and shoulders. For the first time in the game, the Wildcats had scored.</p>
      <p>Energized by the touchdown, the Wildcats played with newfound confidence. Their defense held strong, preventing the Trojans from scoring again. In the final minutes of the game, they managed to score another touchdown and a field goal.</p>
      <p>As the final whistle blew, the scoreboard showed: Wildcats 17, Trojans 14. They had won their first game!</p>
      <p>Amid the celebration, Tyler sought out the Trojans' running back. "Great game," he said, extending his hand. The other boy smiled and shook it, showing true sportsmanship.</p>
      <p>Later, as the team gathered around Coach Martinez, he beamed with pride. "This victory wasn't about one player or one play," he said. "It was about every single one of you believing in yourselves and each other. Remember this feeling, and let it drive you for the rest of the season."</p>
      <p>As Tyler walked off the field, his dad gave him a big hug. "I'm so proud of you, son," he said. "Not just for the touchdown, but for how you played as a team and showed good sportsmanship."</p>
      <p>Tyler grinned, feeling a sense of accomplishment he'd never experienced before. He had faced his fears, played his heart out, and helped his team achieve something special. As he looked at the grass-stained jersey clinging to his pads, he knew this was just the beginning of an exciting season.</p>
      <p>That night, as Tyler drifted off to sleep, he dreamed of more games, more touchdowns, and the roar of the crowd. But most of all, he dreamed of the moments with his teammates, working together to turn the Wildcats from underdogs into champions.</p>
    `
  }


];

const StoryPage = ({ storyId, onBack }) => {
  const story = stories.find(s => s.id === storyId) || stories[0];

  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "Article",
    "headline": story.title,
    "description": story.excerpt,
    "image": `https://www.readingwarriors.com${story.coverImage}`,
    "author": {
      "@type": "Organization",
      "name": "Reading Warriors"
    },
    "publisher": {
      "@type": "Organization",
      "name": "Reading Warriors",
      "logo": {
        "@type": "ImageObject",
        "url": "https://www.readingwarriors.com/static/images/logo.png"
      }
    },
    "datePublished": "2024-08-30",
    "dateModified": "2024-08-30"
  };

  const removeTitle = (content) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');
    const h1 = doc.querySelector('h1');
    if (h1) {
      h1.remove();
    }
    return doc.body.innerHTML;
  };

  return (
    <div className="max-w-3xl mx-auto px-4 py-8">
      <Helmet>
        <title>{`${story.title} | Short Kids Story | Reading Warriors`}</title>
        <meta name="description" content={`Read "${story.title}", a free short story for kids. ${story.excerpt} Perfect for bedtime reading!`} />
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
      </Helmet>

      <button
        onClick={onBack}
        className="mb-6 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-300 shadow-md"
      >
        Back to Stories
      </button>

      <article className="story-content bg-white shadow-lg rounded-lg overflow-hidden">
        <img
          src={story.coverImage}
          alt={story.title}
          className="w-full h-64 object-cover"
        />
        <div className="p-6">
          <h1 className="text-4xl font-bold text-blue-600 mb-6">{story.title}</h1>
          <div
            className="prose prose-lg max-w-none"
            dangerouslySetInnerHTML={{ __html: removeTitle(story.content) }}
          />
        </div>
      </article>

      <section className="mt-12 bg-blue-50 p-6 rounded-lg shadow-md">
        <h2 className="text-2xl font-bold text-blue-600 mb-4">More Kids Stories You'll Love</h2>
        <ul className="space-y-2">
          {stories.filter(s => s.id !== storyId).slice(0, 3).map(s => (
            <li key={s.id}>
              <a
                href={`/stories/${s.id}`}
                className="text-blue-500 hover:text-blue-700 hover:underline transition duration-300"
              >
                {s.title}
              </a>
            </li>
          ))}
        </ul>
      </section>
    </div>
  );
};

export default StoryPage;