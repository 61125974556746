import React, { useState } from 'react';
import { auth } from './firebase.js';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut, GoogleAuthProvider, signInWithPopup, browserPopupRedirectResolver } from 'firebase/auth';
import { useAuth } from './AuthContext.js';
import { initializeUserData } from './userFunctions.js';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSignUp, setIsSignUp] = useState(false);
  const [error, setError] = useState('');
  const { user } = useAuth();

  const handleAuth = async (e) => {
    e.preventDefault();
    setError('');
    try {
      let userCredential;
      if (isSignUp) {
        userCredential = await createUserWithEmailAndPassword(auth, email, password);
        await initializeUserData(userCredential.user.uid, email);
        console.log('Sign up successful');
      } else {
        userCredential = await signInWithEmailAndPassword(auth, email, password);
        console.log('Sign in successful');
      }
    } catch (error) {
      console.error(`Error ${isSignUp ? 'signing up' : 'signing in'}:`, error.message);
      setError(error.message);
    }
  };

  const handleGoogleSignIn = async () => {
    setError('');
    try {
      const provider = new GoogleAuthProvider();
      provider.addScope('https://www.googleapis.com/auth/userinfo.email');
      provider.addScope('https://www.googleapis.com/auth/userinfo.profile');
      provider.setCustomParameters({
        prompt: 'select_account'
      });
      console.log('Initiating Google Sign-In...');
      const result = await signInWithPopup(auth, provider, browserPopupRedirectResolver);
      await initializeUserData(result.user.uid, result.user.email);
      console.log('Google Sign-In Successful:', result.user);
    } catch (error) {
      console.error('Google Sign-In Error:', error);
      setError(`Sign-in error: ${error.message}`);
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      console.log('Sign out successful');
    } catch (error) {
      console.error('Error signing out:', error.message);
      setError(error.message);
    }
  };

  if (user) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-blue-200 to-green-200">
        <div className="p-8 bg-white shadow-lg rounded-3xl">
          <h2 className="text-3xl font-bold mb-6 text-center text-blue-600">Welcome, Brave Reader {user.email}!</h2>
          <button
            onClick={handleSignOut}
            className="w-full bg-yellow-400 text-blue-800 p-3 rounded-full hover:bg-yellow-500 transition duration-300 transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-opacity-50"
          >
            Close Your Book (Sign Out)
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-blue-200 via-green-200 to-yellow-200">
      <div className="w-full max-w-md">
        <form onSubmit={handleAuth} className="bg-white shadow-2xl rounded-3xl px-8 pt-8 pb-10 mb-4 transform hover:scale-105 transition duration-300">
          <h2 className="text-4xl font-bold mb-8 text-center text-blue-600">
            Reading Warriors
          </h2>
          <h3 className="text-2xl font-semibold mb-6 text-center text-green-600">
            {isSignUp ? 'Join the Reading Quest!' : 'Resume Your Adventure!'}
          </h3>
          <div className="mb-6">
            <label className="block text-blue-600 text-lg font-semibold mb-2" htmlFor="email">
              Your Warrior Name (Email)
            </label>
            <input
              className="shadow-md appearance-none border-2 border-blue-200 rounded-full w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              id="email"
              type="email"
              placeholder="brave.reader@readingwarriors.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="mb-8">
            <label className="block text-blue-600 text-lg font-semibold mb-2" htmlFor="password">
              Secret Bookshelf Code (Password)
            </label>
            <input
              className="shadow-md appearance-none border-2 border-blue-200 rounded-full w-full py-3 px-4 text-gray-700 mb-3 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              id="password"
              type="password"
              placeholder="BookwormHero123!"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          {error && <p className="text-red-500 text-sm italic mb-4">{error}</p>}
          <div className="flex flex-col items-center justify-between mb-8">
            <button
              className="w-full bg-green-500 hover:bg-green-600 text-white font-bold py-3 px-4 rounded-full focus:outline-none focus:shadow-outline transition duration-300 transform hover:scale-105"
              type="submit"
            >
              {isSignUp ? 'Begin Your Reading Quest!' : 'Continue Your Adventure!'}
            </button>
            <button
              className="mt-4 font-medium text-lg text-blue-500 hover:text-blue-700 transition duration-200"
              type="button"
              onClick={() => setIsSignUp(!isSignUp)}
            >
              {isSignUp ? 'Already a Reading Warrior? Sign In' : 'New to the Reading Realm? Sign Up'}
            </button>
          </div>
          <div className="flex items-center justify-center">
            <button
              type="button"
              onClick={handleGoogleSignIn}
              className="flex items-center justify-center w-full bg-yellow-100 hover:bg-yellow-200 text-yellow-800 font-bold py-3 px-4 rounded-full focus:outline-none focus:ring-2 focus:ring-yellow-300 transition duration-300 transform hover:scale-105"
            >
              <svg className="w-6 h-6 mr-2" viewBox="0 0 24 24">
                <path
                  fill="#4285F4"
                  d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                />
                <path
                  fill="#34A853"
                  d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                />
                <path
                  fill="#FBBC05"
                  d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                />
                <path
                  fill="#EA4335"
                  d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                />
                <path fill="none" d="M1 1h22v22H1z" />
              </svg>
              Login with Google
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;