import React from 'react';
import { Helmet } from 'react-helmet';
import { FaBook, FaRocket, FaLock, FaCalendarAlt } from 'react-icons/fa';


const stories = [
  {
    id: 'the-littlest-bomber',
    title: 'The Littlest Bomber',
    coverImage: '/static/images/Bombers_Thumbnail.png',
    excerpt: 'The youngest baseball player becoming an unexpected hero.',
  },
  {
    id: 'the-whispering-cornfield',
    title: 'The Whispering Cornfield',
    coverImage: '/static/images/Cornfield_Thumbnail.png',
    excerpt: 'A mysterious adventure on a magical farm where cornfields whisper secrets.',
  },
  {
    id: 'lilys-big-flip',
    title: "Lily's Big Flip",
    coverImage: '/static/images/gymnastics.png',
    excerpt: "A tale of a young gymnast's first big performance.",
  },
  {
    id: 'time-traveling-treehouse',
    title: "The Time-Traveling Treehouse",
    coverImage: '/static/images/treehouse.png',
    excerpt: "An exciting adventure through time in a magical treehouse that appears overnight.",
  },
  {
    id: 'the-melody-maker',
    title: 'The Melody Maker',
    coverImage: '/static/images/melody_maker.png',
    excerpt: 'A young girl discovers her magical musical talent and brings harmony to her town.',
  },
  {
    id: 'the-underdogs-slam-dunk',
    title: "The Underdogs' Slam Dunk",
    coverImage: '/static/images/basketball_team.png',
    excerpt: 'A group of misfit boys come together to form an unlikely basketball team and learn the true meaning of teamwork.',
  },
  {
    id: 'the-whispering-woods',
    title: 'The Whispering Woods',
    coverImage: '/static/images/whispering_woods.png',
    excerpt: 'Two brave friends uncover the secret of a mysterious forest and help a lost spirit find its way home.',
  },
  {
    id: 'the-spirit-squad-surprise',
    title: 'The Spirit Squad Surprise',
    coverImage: '/static/images/spirit_squad.png',
    excerpt: 'A shy girl finds her voice and helps her cheerleading team create an unforgettable routine.',
  },
  {
    id: 'whispers-and-wishes',
    title: 'Whispers and Wishes',
    coverImage: '/static/images/first_horse.png',
    excerpt: 'A dream comes true when she gets her first horse, but she learns that owning a horse is about more than just riding.',
  },
  {
    id: 'secret-treehouse-laboratory',
    title: 'The Secret Treehouse Laboratory',
    coverImage: '/static/images/treehouse_lab.png',
    excerpt: 'Two adventurous boys turn their treehouse into a high-tech laboratory and end up saving their town.',
  },
  {
    id: 'sky-high-challenge',
    title: 'The Sky-High Challenge',
    coverImage: '/static/images/big_roller_coaster.png',
    excerpt: 'A young girl learns to face her fears and discovers the thrill of conquering a massive roller coaster.',
  },
  {
    id: 'underdogs-touchdown',
    title: "The Underdog's Touchdown",
    coverImage: '/static/images/tackle_football.png',
    excerpt: 'A young boy leads his underdog team to victory in their first tackle football game of the season.',
  },
];

const BlogPage = ({ onSelectStory }) => {
  return (
    <div className="max-w-6xl mx-auto px-4 py-8">
      <Helmet>
        <title>Free Short Stories for Kids | Reading Warriors</title>
        <meta name="description" content="Discover a world of free, engaging short stories for kids. From baseball adventures to magical treehouses, find the perfect bedtime story for your child." />
      </Helmet>

      <h1 className="text-4xl font-bold text-blue-600 mb-8">Free Short Stories for Kids</h1>
      <div className="grid gap-6 grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5">
        {stories.map((story) => (
          <div
            key={story.id}
            className="cursor-pointer transition-transform duration-300 hover:scale-105"
            onClick={() => onSelectStory(story.id)}
          >
            <div className="bg-white rounded-lg shadow-md overflow-hidden">
              <img
                src={story.coverImage}
                alt={`Cover for ${story.title}`}
                className="w-full h-40 object-cover"
              />
              <div className="p-2">
                <h2 className="text-sm font-semibold text-gray-800 truncate">{story.title}</h2>
                <p className="text-xs text-gray-600 mt-1 truncate">{story.excerpt}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      <section className="mt-16 bg-gradient-to-r from-blue-50 to-indigo-50 p-8 rounded-xl shadow-lg">
        <h2 className="text-3xl font-bold text-blue-600 mb-8 text-center">Why Parents & Kids Love Reading Warriors</h2>
        <div className="grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
          <div className="bg-white p-6 rounded-lg shadow-md transition-all duration-300 hover:shadow-xl">
            <FaBook className="text-4xl text-blue-500 mb-4" />
            <h3 className="text-xl font-semibold text-gray-800 mb-2">Engaging Stories</h3>
            <p className="text-gray-600">Captivating tales that spark imagination and foster a love for reading</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md transition-all duration-300 hover:shadow-xl">
            <FaRocket className="text-4xl text-green-500 mb-4" />
            <h3 className="text-xl font-semibold text-gray-800 mb-2">Boost Creativity</h3>
            <p className="text-gray-600">Diverse themes to inspire young minds and encourage creative thinking</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md transition-all duration-300 hover:shadow-xl">
            <FaLock className="text-4xl text-purple-500 mb-4" />
            <h3 className="text-xl font-semibold text-gray-800 mb-2">Free Access</h3>
            <p className="text-gray-600">Quality content for young readers, easily accessible</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md transition-all duration-300 hover:shadow-xl">
            <FaCalendarAlt className="text-4xl text-red-500 mb-4" />
            <h3 className="text-xl font-semibold text-gray-800 mb-2">Regular Updates</h3>
            <p className="text-gray-600">New stories added frequently to keep the excitement alive</p>
          </div>
        </div>
      </section>

      <section className="mt-16 text-center">
        <h2 className="text-3xl font-bold text-blue-600 mb-4">Start Your Reading Adventure Today!</h2>
        <p className="text-xl text-gray-600 mb-8">Join thousands of happy families who've made Reading Warriors a part of their daily routine.</p>
        <button
          onClick={() => onSelectStory(stories[0].id)}
          className="bg-blue-500 text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-blue-600 transition duration-300 shadow-md hover:shadow-lg"
        >
          Read Your First Story Now
        </button>
      </section>
    </div>
  );
};

export default BlogPage;