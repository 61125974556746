import React, { createContext, useState, useContext, useEffect, useCallback, useRef } from 'react';
import { auth } from './firebase.js';
import { getUserData } from './userFunctions.js';

const AuthContext = createContext();

const MAX_CALLS_PER_MINUTE = 10;
const QUOTA_EXCEEDED_ERROR = 'Firebase quota exceeded';

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const userDataRef = useRef(null);
  const lastFetchRef = useRef(0);
  const apiCallsCountRef = useRef(0);
  const apiCallsResetTimeRef = useRef(Date.now());

  const setUserData = useCallback((data) => {
    userDataRef.current = data;
  }, []);

  const checkRateLimit = useCallback(() => {
    const now = Date.now();
    if (now - apiCallsResetTimeRef.current > 60000) {
      apiCallsCountRef.current = 0;
      apiCallsResetTimeRef.current = now;
    }

    apiCallsCountRef.current += 1;

    if (apiCallsCountRef.current > MAX_CALLS_PER_MINUTE) {
      throw new Error('Rate limit exceeded. Too many API calls in a short period.');
    }
  }, []);

  const refreshUserData = useCallback(async (force = false) => {
    if (user) {
      const now = Date.now();
      if (force || !userDataRef.current || now - lastFetchRef.current > 5 * 60 * 1000) {
        try {
          checkRateLimit();
          console.log('Refreshing user data for user:', user.uid);
          const freshUserData = await getUserData(user.uid);
          console.log('Fresh user data:', freshUserData);
          if (JSON.stringify(freshUserData) !== JSON.stringify(userDataRef.current)) {
            setUserData(freshUserData);
          }
          lastFetchRef.current = now;
        } catch (error) {
          console.error('Error refreshing user data:', error);
          if (error.message.includes(QUOTA_EXCEEDED_ERROR)) {
            setError('Firebase quota exceeded. Please try again later.');
          } else if (error.message.includes('Rate limit exceeded')) {
            setError(error.message);
          }
          throw error;
        }
      } else {
        console.log('Using cached user data');
      }
    }
  }, [user, setUserData, checkRateLimit]);

  useEffect(() => {
    let isMounted = true;
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (isMounted) {
        console.log('Auth state changed. User:', user ? user.uid : 'No user');
        setUser(user);
        if (user) {
          try {
            await refreshUserData(true);
          } catch (error) {
            console.error('Error in initial user data refresh:', error);
          }
        } else {
          setUserData(null);
          lastFetchRef.current = 0;
        }
        setIsLoading(false);
      }
    });

    return () => {
      isMounted = false;
      unsubscribe();
    };
  }, [refreshUserData, setUserData]);

  const value = {
    user,
    userData: userDataRef.current,
    refreshUserData,
    isLoading,
    error,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};